// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const HomeScreen = () => {
//   const navigate = useNavigate();

//   const handleDeliverToday = () => {
//     navigate('/deliver-today');
//   };

//   const handleViewHistory = () => {
//     navigate('/history');
//   };

//   return (
//     <div className="flex flex-col min-h-screen bg-sky-50 p-6">
//       {/* Logo and title container */}
//       <div className="flex flex-col items-center justify-center mt-8 mb-6">
//         {/* Tooth logo SVG */}
//         <div className="w-32 h-32 mb-4">
//           <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path 
//               d="M60 10C45 10 35 20 30 30C25 40 20 45 15 50C10 55 10 70 15 85C20 100 25 110 35 110C45 110 50 95 55 80C56 76 58 74 60 74C62 74 64 76 65 80C70 95 75 110 85 110C95 110 100 100 105 85C110 70 110 55 105 50C100 45 95 40 90 30C85 20 75 10 60 10Z" 
//               fill="#F0F8FF" 
//               stroke="#60A5FA" 
//               strokeWidth="4"
//             />
//           </svg>
//         </div>
        
//         {/* App title */}
//         <h1 className="text-3xl font-bold text-sky-700">KJB Delivery</h1>
//       </div>

//       {/* Welcome message */}
//       <div className="text-center mb-8">
//         <p className="text-lg text-sky-800">Welcome to your dental delivery assistant!</p>
//       </div>

//       {/* Buttons container */}
//       <div className="flex flex-col gap-6 mt-4 mb-8">
//         {/* Deliver Today button */}
//         <button 
//           onClick={handleDeliverToday}
//           className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-4 px-6 rounded-xl shadow-md transition-colors duration-300 active:scale-95"
//         >
//           <span className="text-2xl mr-3">📦</span>
//           <span className="text-xl font-semibold">Deliver Today</span>
//         </button>

//         {/* View History button */}
//         <button 
//           onClick={handleViewHistory}
//           className="flex items-center justify-center bg-teal-500 hover:bg-teal-600 text-white py-4 px-6 rounded-xl shadow-md transition-colors duration-300 active:scale-95"
//         >
//           <span className="text-2xl mr-3">📋</span>
//           <span className="text-xl font-semibold">View History</span>
//         </button>
//       </div>

//       {/* Footer */}
//       <div className="mt-auto text-center py-4">
//         <p className="text-sky-600 italic">Helping smiles shine brighter!</p>
//       </div>
//     </div>
//   );
// };

// export default HomeScreen;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Import auth from your firebase.js file
import { signOut } from 'firebase/auth'; // Import signOut function

const HomeScreen = () => {
  const navigate = useNavigate();
  
  const handleDeliverToday = () => {
    navigate('/deliver-today');
  };
  
  const handleViewHistory = () => {
    navigate('/history');
  };
  
  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Redirect to login page after successful logout
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
      // You could add error handling here (optional)
    }
  };
  
  return (
    <div className="flex flex-col min-h-screen bg-sky-50 p-6">
      {/* Header with logout button */}
      <div className="flex justify-end mb-4">
        <button
          onClick={handleLogout}
          className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded-lg transition-colors duration-300"
        >
          <span className="text-sm mr-2">🚪</span>
          <span className="text-sm font-medium">Logout</span>
        </button>
      </div>
      
      {/* Logo and title container */}
      <div className="flex flex-col items-center justify-center mt-4 mb-6">
        {/* Tooth logo SVG */}
        <div className="w-32 h-32 mb-4">
          <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M60 10C45 10 35 20 30 30C25 40 20 45 15 50C10 55 10 70 15 85C20 100 25 110 35 110C45 110 50 95 55 80C56 76 58 74 60 74C62 74 64 76 65 80C70 95 75 110 85 110C95 110 100 100 105 85C110 70 110 55 105 50C100 45 95 40 90 30C85 20 75 10 60 10Z"
              fill="#F0F8FF"
              stroke="#60A5FA"
              strokeWidth="4"
            />
          </svg>
        </div>
        {/* App title */}
        <h1 className="text-3xl font-bold text-sky-700">KJB Delivery</h1>
      </div>
      
      {/* Welcome message */}
      <div className="text-center mb-8">
        <p className="text-lg text-sky-800">Welcome to your dental delivery assistant!</p>
      </div>
      
      {/* Buttons container */}
      <div className="flex flex-col gap-6 mt-4 mb-8">
        {/* Deliver Today button */}
        <button
          onClick={handleDeliverToday}
          className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-4 px-6 rounded-xl shadow-md transition-colors duration-300 active:scale-95"
        >
          <span className="text-2xl mr-3">📦</span>
          <span className="text-xl font-semibold">Deliver Today</span>
        </button>
        
        {/* View History button */}
        <button
          onClick={handleViewHistory}
          className="flex items-center justify-center bg-teal-500 hover:bg-teal-600 text-white py-4 px-6 rounded-xl shadow-md transition-colors duration-300 active:scale-95"
        >
          <span className="text-2xl mr-3">📋</span>
          <span className="text-xl font-semibold">View History</span>
        </button>
      </div>
      
      {/* Footer */}
      <div className="mt-auto text-center py-4">
        <p className="text-sky-600 italic">Helping smiles shine brighter!</p>
      </div>
    </div>
  );
};

export default HomeScreen;