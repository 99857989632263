import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { 
  collection, 
  doc, 
  getDoc, 
  getDocs,
  query,
  orderBy
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const HistoryScreen = () => {
  const navigate = useNavigate();
  
  // States
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [historyDates, setHistoryDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateDetails, setDateDetails] = useState(null);
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'details'
  
  // Get day name
  const getDayName = (dateStr) => {
    // const date = new Date(dateStr);
    const date = new Date(`${dateStr}T12:00:00`);
    console.log(date)
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  };

  
  // Format date for display
  // const formatDate = (dateStr) => {
  //   const date = new Date(dateStr);
  //   return date.toLocaleDateString('en-US', { 
  //     year: 'numeric', 
  //     month: 'long', 
  //     day: 'numeric' 
  //   });
  // };
  const formatDate = (dateStr) => {
    // Add a specific time (noon) to avoid timezone issues
    const date = new Date(`${dateStr}T12:00:00`);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  // Check auth state on component mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      
      if (!currentUser) {
        // Redirect to home if not logged in
        navigate('/');
      } else {
        // Load history dates
        loadHistoryDates(currentUser.uid);
      }
      
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, [navigate]);
  
  // Load history dates from Firestore
  const loadHistoryDates = async (userId) => {
    try {
      setLoading(true);
      
      // Query history collection ordered by date (newest first)
      const historyQuery = query(
        collection(db, `users/${userId}/history`),
        orderBy('date', 'desc')
      );
      
      const historySnapshot = await getDocs(historyQuery);
      const dates = [];
      
      historySnapshot.forEach((doc) => {
        const data = doc.data();
        const firestoreTimestamp = data.date;
        let dateObj;
        
        if (firestoreTimestamp && firestoreTimestamp.toDate) {
          // If it's a Firestore timestamp object
          dateObj = firestoreTimestamp.toDate();
        } else {
          // Fallback to document ID if timestamp is not available
          dateObj = new Date(doc.id);
        }
        
        // Format date in local timezone for display
        const localDateString = dateObj.toISOString().split('T')[0];
        dates.push({
          id: doc.id,
          date: localDateString, // Using the document ID which is the date string
          timestamp: data.date,
          completed: data.completed || false,
          locationCount: data.locations?.length || 0
        });
      });
      
      setHistoryDates(dates);
      
      // If we have dates, select the most recent one
      if (dates.length > 0) {
        setSelectedDate(dates[0].date);
      }
      
    } catch (err) {
      console.error("Error loading history dates:", err);
      setError("Failed to load history data. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  // Load details for a specific date
  const loadDateDetails = async (date) => {
    if (!user || !date) return;
    
    try {
      setLoading(true);
      setSelectedDate(date);
      
      // Get history document for the selected date
      const docRef = doc(db, `users/${user.uid}/history`, date);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        setDateDetails(data);
        setViewMode('details');
      } else {
        setError("No details found for this date.");
        setDateDetails(null);
      }
      
    } catch (err) {
      console.error("Error loading date details:", err);
      setError("Failed to load details for the selected date.");
      setDateDetails(null);
    } finally {
      setLoading(false);
    }
  };
  
  // Calculate totals for a day
  const calculateTotals = (locations) => {
    if (!locations || locations.length === 0) {
      return { pickup: 0, drop: 0, total: 0 };
    }
    
    const pickup = locations.reduce((sum, location) => sum + (location.pickup || 0), 0);
    const drop = locations.reduce((sum, location) => sum + (location.drop || 0), 0);
    
    return {
      pickup,
      drop,
      total: pickup + drop
    };
  };
  
  // Return to list view
  const backToList = () => {
    setViewMode('list');
    setDateDetails(null);
  };
  
  // Loading state
  if (loading && !user) {
    return (
      <div className="flex justify-center items-center h-screen bg-sky-50">
        <div className="text-center">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="w-12 h-12 mx-auto mb-4 text-blue-500 animate-spin"
            fill="none" 
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
            />
          </svg>
          <p className="text-sky-700">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-sky-50 p-4">
      {/* Header with back button */}
      <div className="flex items-center mb-4">
        <button 
          onClick={() => navigate('/')}
          className="flex items-center justify-center p-2 mr-3 rounded-full bg-white shadow"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6 text-sky-700" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <h1 className="text-2xl font-bold text-sky-700">Delivery History</h1>
      </div>
      
      {/* Error message */}
      {error && (
        <div className="p-3 mb-4 bg-red-100 text-red-700 rounded-lg">
          {error}
          <button 
            onClick={() => setError(null)}
            className="ml-2 font-bold"
          >
            ✕
          </button>
        </div>
      )}
      
      {viewMode === 'list' ? (
        /* LIST VIEW - Show all history dates */
        <>
          <div className="bg-white rounded-xl shadow p-4 mb-4">
            <h2 className="text-lg font-bold text-gray-800 mb-2">Past Deliveries</h2>
            <p className="text-sm text-gray-600">
              {historyDates.length > 0 
                ? "Select a date to view delivery details." 
                : "No delivery history found."}
            </p>
          </div>
          
          {/* History date list */}
          <div className="mb-6">
            {historyDates.length === 0 ? (
              <div className="bg-white rounded-xl shadow p-4 text-center">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-12 w-12 mx-auto mb-2 text-gray-400" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
                  />
                </svg>
                <p className="text-gray-600">No delivery history found</p>
                <button 
                  onClick={() => navigate('/deliver-today')}
                  className="mt-3 px-4 py-2 bg-sky-500 text-white rounded-lg font-medium"
                >
                  Start Today's Deliveries
                </button>
              </div>
            ) : (
              historyDates.map((historyDate) => (
                <div 
                  key={historyDate.id}
                  onClick={() => loadDateDetails(historyDate.date)}
                  className="bg-white rounded-xl shadow mb-3 p-4 cursor-pointer hover:shadow-md transition-shadow"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="flex items-center">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          className="h-5 w-5 mr-2 text-sky-600" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
                          />
                        </svg>
                        <h3 className="font-bold text-gray-800">{formatDate(historyDate.date)}</h3>
                      </div>
                      <p className="text-xs text-gray-500 mt-1 ml-7">
                        {getDayName(historyDate.date)}
                      </p>
                    </div>
                    
                    <div className="flex items-center">
                      <div className="bg-sky-100 text-sky-800 px-3 py-1 rounded-full text-sm font-medium">
                        {historyDate.locationCount} {historyDate.locationCount === 1 ? 'Location' : 'Locations'}
                      </div>
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-5 w-5 ml-2 text-gray-400" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M9 5l7 7-7 7" 
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      ) : (
        /* DETAILS VIEW - Show details for selected date */
        <>
          {/* Date header */}
          <div className="bg-white rounded-xl shadow p-4 mb-4">
            <div className="flex items-center">
              <button 
                onClick={backToList}
                className="flex items-center justify-center p-1.5 mr-3 rounded-full bg-gray-100"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5 text-gray-600" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <div>
                <h2 className="text-xl font-bold text-gray-800">{formatDate(selectedDate)}</h2>
                <p className="text-sm text-gray-600">{getDayName(selectedDate)}</p>
              </div>
            </div>
          </div>
          
          {loading ? (
            <div className="bg-white rounded-xl shadow p-4 text-center">
              <svg 
                className="w-8 h-8 mx-auto mb-2 text-blue-500 animate-spin"
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
                />
              </svg>
              <p className="text-sky-700">Loading delivery details...</p>
            </div>
          ) : dateDetails ? (
            <>
              {/* Summary Card */}
              <div className="bg-white rounded-xl shadow p-4 mb-4">
                <h3 className="font-bold text-gray-800 mb-3">Delivery Summary</h3>
                {dateDetails.locations && (
                  <div className="flex justify-between border-t pt-3 border-gray-200">
                    <div className="text-center">
                      <div className="text-xs text-gray-500">Locations</div>
                      <div className="text-lg font-bold text-sky-600">
                        {dateDetails.locations.length}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-xs text-gray-500">Pickup</div>
                      <div className="text-lg font-bold text-green-600">
                        {calculateTotals(dateDetails.locations).pickup}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-xs text-gray-500">Drop</div>
                      <div className="text-lg font-bold text-red-600">
                        {calculateTotals(dateDetails.locations).drop}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-xs text-gray-500">Total</div>
                      <div className="text-lg font-bold text-purple-600">
                        {calculateTotals(dateDetails.locations).total}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Locations List */}
              <div className="mb-6">
                <h3 className="font-bold text-gray-800 mb-2 px-1">Delivery Locations</h3>
                {dateDetails.locations?.length > 0 ? (
                  dateDetails.locations.map((location, index) => (
                    <div 
                      key={location.id || index}
                      className="bg-white rounded-xl shadow mb-3 overflow-hidden"
                    >
                      {/* Location Header */}
                      <div className="p-3 border-b border-gray-200">
                        <div className="flex items-center">
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-4 w-4 mr-1 text-sky-600" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" 
                            />
                          </svg>
                          <h3 className="font-bold text-gray-800">{location.name}</h3>
                        </div>
                        
                        <p className="text-xs text-gray-500 mt-1 ml-5">
                          {location.address}
                        </p>
                      </div>
                      
                      {/* Location Details */}
                      <div className="p-3 flex justify-around">
                        {/* Pickup Count */}
                        <div className="text-center">
                          <div className="text-xs text-gray-500 mb-1">Pickup</div>
                          <div className="text-lg font-bold text-green-600 flex items-center justify-center">
                            {location.pickup || 0}
                            {location.pickupReminder && (
                              <span className="ml-1 w-2 h-2 bg-blue-500 rounded-full"></span>
                            )}
                          </div>
                        </div>
                        
                        {/* Drop Count */}
                        <div className="text-center">
                          <div className="text-xs text-gray-500 mb-1">Drop</div>
                          <div className="text-lg font-bold text-red-600">
                            {location.drop || 0}
                          </div>
                        </div>
                        
                        {/* Total Count */}
                        <div className="text-center">
                          <div className="text-xs text-gray-500 mb-1">Total</div>
                          <div className="text-lg font-bold text-purple-600">
                            {(location.pickup || 0) + (location.drop || 0)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="bg-white rounded-xl shadow p-4 text-center">
                    <p className="text-gray-600">No locations found for this date</p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="bg-white rounded-xl shadow p-4 text-center">
              <p className="text-gray-600">No details available for this date</p>
              <button 
                onClick={backToList}
                className="mt-3 px-4 py-2 bg-sky-500 text-white rounded-lg font-medium"
              >
                Back to History
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HistoryScreen;