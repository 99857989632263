


// import React, { useEffect, useState } from 'react';
// import { Camera, Mail, Lock } from 'lucide-react';
// import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../firebase'; // Import auth from your firebase.js file
// import { useNavigate } from 'react-router-dom'; // For navigation

// const LoginScreen = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [isLogin, setIsLogin] = useState(true);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
  
//   // For navigation after successful auth
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError('');
//     setSuccess('');

//     try {
//       if (isLogin) {
//         const result = await signInWithEmailAndPassword(auth, email, password);
//         setSuccess(`Successfully logged in as ${result.user.email}`);
        
//         // Redirect to HomeScreen after successful login
//         setTimeout(() => {
//           navigate('/'); // Navigate to the home route
//         }, 1000); // Short delay to show success message
//       } else {
//         const result = await createUserWithEmailAndPassword(auth, email, password);
//         setSuccess(`Account created successfully for ${result.user.email}`);
        
//         // Redirect to HomeScreen after successful signup
//         setTimeout(() => {
//           navigate('/'); // Navigate to the home route
//         }, 1000); // Short delay to show success message
//       }
//     } catch (err) {
//       setError(err.message || 'An error occurred');
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(()=>{
//     if (!isLogin) {
//         setEmail('')
//         setPassword('')
//     }
//   },[isLogin])

//   return (
//     <div className="min-h-screen bg-gradient-to-b from-blue-50 to-blue-100 flex flex-col items-center justify-center p-4">
//       {/* Logo and Header */}
//       <div className="mb-8 text-center">
//         <div className="flex items-center justify-center mb-2">
//           <div className="bg-blue-500 text-white p-3 rounded-full">
//             <Camera size={28} />
//           </div>
//           <div className="ml-2 bg-blue-600 text-white p-3 rounded-full">
//             <Lock size={28} />
//           </div>
//         </div>
//         <h1 className="text-3xl font-bold text-blue-800">KJB Dental Lab</h1>
//         <p className="text-blue-600 mt-1">Professional Dental Solutions</p>
//       </div>

//       {/* Card Container */}
//       <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
//         <div className="mb-6 flex">
//           <button 
//             className={`flex-1 py-2 font-medium ${isLogin ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
//             onClick={() => setIsLogin(true)}
//           >
//             Login
//           </button>
//           <button 
//             className={`flex-1 py-2 font-medium ${!isLogin ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
//             onClick={() => setIsLogin(false)}
//           >
//             Sign Up
//           </button>
//         </div>

//         {error && (
//           <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-md text-sm">
//             {error}
//           </div>
//         )}

//         {success && (
//           <div className="mb-4 p-3 bg-green-50 text-green-600 rounded-md text-sm">
//             {success}
//           </div>
//         )}

//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="email">
//               Email Address
//             </label>
//             <div className="relative">
//               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                 <Mail size={16} className="text-gray-400" />
//               </div>
//               <input
//                 id="email"
//                 type="email"
//                 className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//             </div>
//           </div>
          
//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="password">
//               Password
//             </label>
//             <div className="relative">
//               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                 <Lock size={16} className="text-gray-400" />
//               </div>
//               <input
//                 id="password"
//                 type="password"
//                 className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//               />
//             </div>
//           </div>

//           {isLogin && (
//             <div className="flex justify-end mb-6">
//               <button type="button" className="text-sm text-blue-600 hover:text-blue-800">
//                 Forgot Password?
//               </button>
//             </div>
//           )}

//           <button
//             type="submit"
//             className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
//             disabled={loading}
//           >
//             {loading ? 'Processing...' : isLogin ? 'Login' : 'Create Account'}
//           </button>
//         </form>

//         <div className="mt-6 pt-4 border-t border-gray-200 text-center text-sm text-gray-600">
//           {isLogin ? "Don't have an account? " : "Already have an account? "}
//           <button 
//             className="text-blue-600 hover:text-blue-800 font-medium"
//             onClick={() => setIsLogin(!isLogin)}
//           >
//             {isLogin ? 'Sign Up' : 'Login'}
//           </button>
//         </div>
//       </div>

//       {/* Dental imagery */}
//       <div className="mt-8 flex gap-4">
//         <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
//           <div className="w-6 h-6 bg-white rounded-full" />
//         </div>
//         <div className="w-8 h-8 bg-blue-200 rounded-full flex items-center justify-center">
//           <div className="w-6 h-6 bg-white rounded-full" />
//         </div>
//         <div className="w-8 h-8 bg-blue-300 rounded-full flex items-center justify-center">
//           <div className="w-6 h-6 bg-white rounded-full" />
//         </div>
//       </div>
      
//       <p className="text-xs text-gray-500 mt-6">
//         © 2025 KJB Dental Lab. All rights reserved.
//       </p>
//     </div>
//   );
// };

// export default LoginScreen;

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Camera, Mail, Lock } from 'lucide-react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // Adjust path as needed

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get the intended destination from location state, or default to home
  const from = location.state?.from?.pathname || '/';
  
  // Check if user is already logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is already logged in, redirect to intended destination
        navigate(from, { replace: true });
      }
    });
    
    return () => unsubscribe();
  }, [from, navigate]);

  useEffect(() => {
    if (!isLogin) {
      setEmail('');
      setPassword('');
    }
  }, [isLogin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      if (isLogin) {
        const result = await signInWithEmailAndPassword(auth, email, password);
        setSuccess(`Successfully logged in as ${result.user.email}`);
        
        // Navigate to original intended route after successful login
        setTimeout(() => {
          navigate(from, { replace: true });
        }, 1000); // Short delay to show success message
      } else {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        setSuccess(`Account created successfully for ${result.user.email}`);
        
        // Navigate to home after successful signup
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 1000); // Short delay to show success message
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-blue-100 flex flex-col items-center justify-center p-4">
      {/* Logo and Header */}
      <div className="mb-8 text-center">
        <div className="flex items-center justify-center mb-2">
          <div className="ml-2 bg-blue-600 text-white p-3 rounded-full">
            <Lock size={28} />
          </div>
        </div>
        <h1 className="text-3xl font-bold text-blue-800">KJB Dental Lab</h1>
        <p className="text-blue-600 mt-1">Professional Dental Solutions</p>
      </div>

      {/* Card Container */}
      <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
        <div className="mb-6 flex">
          <button 
            className={`flex-1 py-2 font-medium ${isLogin ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
            onClick={() => setIsLogin(true)}
          >
            Login
          </button>
          <button 
            className={`flex-1 py-2 font-medium ${!isLogin ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
            onClick={() => setIsLogin(false)}
          >
            Sign Up
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-md text-sm">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-3 bg-green-50 text-green-600 rounded-md text-sm">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="email">
              Email Address
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail size={16} className="text-gray-400" />
              </div>
              <input
                id="email"
                type="email"
                className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="password">
              Password
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock size={16} className="text-gray-400" />
              </div>
              <input
                id="password"
                type="password"
                className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>

          {/* {isLogin && (
            <div className="flex justify-end mb-6">
              <button type="button" className="text-sm text-blue-600 hover:text-blue-800">
                Forgot Password?
              </button>
            </div>
          )} */}

          <button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
            disabled={loading}
          >
            {loading ? 'Processing...' : isLogin ? 'Login' : 'Create Account'}
          </button>
        </form>

        <div className="mt-6 pt-4 border-t border-gray-200 text-center text-sm text-gray-600">
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <button 
            className="text-blue-600 hover:text-blue-800 font-medium"
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? 'Sign Up' : 'Login'}
          </button>
        </div>
      </div>

      {/* Dental imagery */}
      <div className="mt-8 flex gap-4">
        <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
          <div className="w-6 h-6 bg-white rounded-full" />
        </div>
        <div className="w-8 h-8 bg-blue-200 rounded-full flex items-center justify-center">
          <div className="w-6 h-6 bg-white rounded-full" />
        </div>
        <div className="w-8 h-8 bg-blue-300 rounded-full flex items-center justify-center">
          <div className="w-6 h-6 bg-white rounded-full" />
        </div>
      </div>
      
      <p className="text-xs text-gray-500 mt-6">
        © 2025 KJB Dental Lab. All rights reserved.
      </p>
    </div>
  );
};

export default LoginScreen;