import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  getDocs,
  deleteDoc,  // Add this import
  Timestamp 
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const DeliverTodayScreen = () => {
  const navigate = useNavigate();
  
  // States
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState('');
  const [currentDay, setCurrentDay] = useState('');
  const [locations, setLocations] = useState([]);
  const [allCompleted, setAllCompleted] = useState(false);
  const [viewMode, setViewMode] = useState('select'); // 'select' or 'deliver'
  const [searchTerm, setSearchTerm] = useState('');

  const [showSavePopup, setShowSavePopup] = useState(false);

  const isBeforeEarlyClosing = () => {
    const date = new Date();
    const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, etc.
    const currentHour = date.getHours();
    const currentMinute = date.getMinutes();
    
    // Tuesday (2) or Friday (5) - early closing days (2 PM)
    if ((dayOfWeek === 2 || dayOfWeek === 5) && (currentHour < 14 || (currentHour === 14 && currentMinute === 0))) {
      return true;
    }
    
    return false;
  };
  
  // Get day name
  const getDayName = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  };
  
  // Get today's hours based on day of week
  const getTodaysHours = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date();
    const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, etc.
    
    // Monday (1), Wednesday (3), Thursday (4) have regular hours
    if (dayOfWeek === 1 || dayOfWeek === 3 || dayOfWeek === 4) {
      return "9:00 AM - 5:00 PM";
    } 
    // Tuesday (2) or Friday (5) have shorter hours
    else if (dayOfWeek === 2 || dayOfWeek === 5) {
      return "9:00 AM - 2:00 PM";
    }
    // Weekend - not operating
    else {
      return "Not operating on weekends";
    }
  };

  // Check auth state on component mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      
      if (!currentUser) {
        // Redirect to home if not logged in
        navigate('/');
      } else {
        // Set current date
        const today = new Date();
        const todayStr = today.toISOString().split('T')[0];
        setCurrentDate(todayStr);
        setCurrentDay(getDayName(today));
        
        // Load data
        loadData(currentUser.uid);
      }
      
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, [navigate]);
  
  // Load data from Firestore
  const loadData = async (userId) => {
    try {
      setLoading(true);
      
      // Load dental locations from Firestore
      const locationsSnapshot = await getDocs(collection(db, 'dentalLocations'));
      const locationsData = [];
      
      locationsSnapshot.forEach((doc) => {
        locationsData.push({
          id: doc.id,
          ...doc.data(),
          scheduledForToday: false,
          completed: false,
          deleted: false,
          pickup: 0,
          drop: 0,
          pickupReminder: false
        });
      });
      
      // Check for today's schedule
      const todayDoc = await getDoc(doc(db, `users/${userId}/trackerDays`, new Date().toISOString().split('T')[0]));
      
      if (todayDoc.exists()) {
        const todayData = todayDoc.data();
        
        // Update locations with scheduled status from today's data
        const updatedLocations = locationsData.map(location => {
          const scheduledLocation = todayData.offices?.find(office => 
            office.id.toString() === location.id.toString()
          );
          
          if (scheduledLocation && scheduledLocation.scheduledForToday) {
            return {
              ...location,
              scheduledForToday: true,
              pickup: scheduledLocation.pickup || 0,
              drop: scheduledLocation.drop || 0,
              pickupReminder: scheduledLocation.pickupReminder || false
            };
          }
          
          return location;
        });
        
        setLocations(updatedLocations);
        
        // If we already have scheduled locations, go directly to deliver mode
        if (updatedLocations.some(loc => loc.scheduledForToday)) {
          setViewMode('deliver');
        }
      } else {
        setLocations(locationsData);
      }
      
    } catch (err) {
      console.error("Error loading data:", err);
      setError("Failed to load locations. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  // Toggle scheduled status
  const toggleScheduled = (id) => {
    setLocations(locations.map(location => {
      if (location.id.toString() === id.toString()) {
        return {
          ...location,
          scheduledForToday: !location.scheduledForToday
        };
      }
      return location;
    }));
  };
  
  // Toggle location completion status
  const toggleCompleted = (id) => {
    const updatedLocations = locations.map(location => {
      if (location.id.toString() === id.toString()) {
        return {
          ...location,
          completed: !location.completed,
          deleted: false // Reset deleted status if completed
        };
      }
      return location;
    });
    
    setLocations(updatedLocations);
    
    // Check if all scheduled locations are completed or deleted
    checkAllCompleted(updatedLocations);
  };
  
  // Remove location from schedule when canceled
  const toggleDeleted = (id) => {
    const updatedLocations = locations.map(location => {
      if (location.id.toString() === id.toString()) {
        return {
          ...location,
          scheduledForToday: false, // Remove from schedule
          completed: false,
          deleted: false,
          pickup: 0,
          drop: 0
        };
      }
      return location;
    });
    
    setLocations(updatedLocations);
    
    // Check if all scheduled locations are completed or deleted
    checkAllCompleted(updatedLocations);
  };
  
  // Toggle pickup reminder
  const togglePickupReminder = (id) => {
    setLocations(locations.map(location => {
      if (location.id.toString() === id.toString()) {
        return {
          ...location,
          pickupReminder: !location.pickupReminder
        };
      }
      return location;
    }));
  };
  
  // Update pickup count
  const updatePickup = (id, increment) => {
    const updatedLocations = locations.map(location => {
      if (location.id.toString() === id.toString()) {
        return {
          ...location,
          pickup: Math.max(0, location.pickup + increment)
        };
      }
      return location;
    });
    
    setLocations(updatedLocations);
  };
  
  // Update drop count
  const updateDrop = (id, increment) => {
    const updatedLocations = locations.map(location => {
      if (location.id.toString() === id.toString()) {
        return {
          ...location,
          drop: Math.max(0, location.drop + increment)
        };
      }
      return location;
    });
    
    setLocations(updatedLocations);
  };
  
  // Check if all scheduled locations are completed
  const checkAllCompleted = (updatedLocations) => {
    const scheduledLocations = updatedLocations.filter(location => location.scheduledForToday);
    
    if (scheduledLocations.length === 0) {
      setAllCompleted(false);
      return;
    }
    
    // Now we only check for completed status since deleted locations are removed
    const allDone = scheduledLocations.every(location => location.completed);
    setAllCompleted(allDone);
  };
  

    
// const saveToday = async () => {
//   if (!user) return;
  
//   try {
//     setLoading(true);
    
//     // Prepare data for saving
//     const scheduledLocations = locations.filter(location => location.scheduledForToday);
    
//     // 1. Create history document
//     await setDoc(
//       doc(db, `users/${user.uid}/history`, currentDate), 
//       {
//         date: Timestamp.fromDate(new Date(currentDate)),
//         locations: scheduledLocations,
//         completed: true,
//         updatedAt: Timestamp.now()
//       }
//     );
    
//     // 2. Delete the trackerDays document for today
//     await deleteDoc(doc(db, `users/${user.uid}/trackerDays`, currentDate));
    
//     alert("Today's deliveries saved successfully!");
    
//     // Navigate back to home
//     navigate('/');
    
//   } catch (err) {
//     console.error("Error saving data:", err);
//     setError("Failed to save delivery data. Please try again.");
//   } finally {
//     setLoading(false);
//   }
// };
const saveToday = async () => {
  if (!user) return;
  
  // Show confirmation popup instead of saving immediately
  setShowSavePopup(true);
};

// Add this new function to handle the actual saving process
const handleConfirmSave = async () => {
  if (!user) return;
  
  try {
    setLoading(true);
    
    // Prepare data for saving
    const scheduledLocations = locations.filter(location => location.scheduledForToday);
    
    // 1. Create history document
    await setDoc(
      doc(db, `users/${user.uid}/history`, currentDate), 
      {
        date: Timestamp.fromDate(new Date(currentDate)),
        locations: scheduledLocations,
        completed: true,
        updatedAt: Timestamp.now()
      }
    );
    
    // 2. Delete the trackerDays document for today
    await deleteDoc(doc(db, `users/${user.uid}/trackerDays`, currentDate));
    
    // Close popup
    setShowSavePopup(false);
    
    alert("Today's deliveries saved successfully!");
    
    // Navigate back to home
    navigate('/');
    
  } catch (err) {
    console.error("Error saving data:", err);
    setError("Failed to save delivery data. Please try again.");
  } finally {
    setLoading(false);
  }
};
  // Save selected locations and switch to deliver mode
  const saveSelections = async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      
      // Update tracker data with selected locations
      await setDoc(
        doc(db, `users/${user.uid}/trackerDays`, currentDate), 
        {
          offices: locations,
          date: Timestamp.fromDate(new Date(currentDate)),
          updatedAt: Timestamp.now()
        }
      );
      
      // Switch to deliver mode
      setViewMode('deliver');
      
    } catch (err) {
      console.error("Error saving selected locations:", err);
      setError("Failed to save selected locations. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  // Get scheduled locations
  const scheduledLocations = locations.filter(location => location.scheduledForToday);
  
  // Get filtered locations
  const filteredLocations = locations.filter(location => 
    location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    location.address.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  // Calculate totals
  const totalPickup = scheduledLocations.reduce((sum, location) => sum + location.pickup, 0);
  const totalDrop = scheduledLocations.reduce((sum, location) => sum + location.drop, 0);
  const totalItems = totalPickup + totalDrop;
  
  // Loading state
  if (loading && !user) {
    return (
      <div className="flex justify-center items-center h-screen bg-sky-50">
        <div className="text-center">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="w-12 h-12 mx-auto mb-4 text-blue-500 animate-spin"
            fill="none" 
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
            />
          </svg>
          <p className="text-sky-700">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-sky-50 p-4">
      {/* Header with back button */}
      <div className="flex items-center mb-4">
        <button 
          onClick={() => navigate('/')}
          className="flex items-center justify-center p-2 mr-3 rounded-full bg-white shadow"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6 text-sky-700" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <h1 className="text-2xl font-bold text-sky-700">Deliver Today</h1>
      </div>
      
      {/* Error message */}
      {error && (
        <div className="p-3 mb-4 bg-red-100 text-red-700 rounded-lg">
          {error}
          <button 
            onClick={() => setError(null)}
            className="ml-2 font-bold"
          >
            ✕
          </button>
        </div>
      )}
      
      {/* Date display */}
      <div className="bg-white rounded-xl shadow p-4 mb-4 flex items-center">
        <div className="bg-sky-100 rounded-full p-3 mr-3">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6 text-sky-600" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
            />
          </svg>
        </div>
        <div>
          <h2 className="text-xl font-bold text-sky-800">{currentDay}</h2>
          <p className="text-gray-600">{currentDate}</p>
          <p className="text-sm text-gray-500">{getTodaysHours(currentDate)}</p>
        </div>
      </div>
      
      {/* {viewMode === 'select' ? (
  
        <>
          <div className="bg-white rounded-xl shadow p-4 mb-4">
            <h2 className="text-lg font-bold text-gray-800 mb-2">Select Locations for Today</h2>
            <p className="text-sm text-gray-600 mb-4">Tap on locations to add them to today's schedule.</p>
            

            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Search locations..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-3 pl-10 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-sky-500"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 absolute left-3 top-3.5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            
      
            <div className="flex justify-between items-center mb-4 py-2 px-3 bg-sky-50 rounded-lg">
              <span className="text-sky-700 font-medium">Selected Locations:</span>
              <span className="bg-sky-100 text-sky-800 font-bold px-3 py-1 rounded-full">
                {scheduledLocations.length}
              </span>
            </div>
          </div>
          
 
          <div className="mb-24">
            {filteredLocations.length === 0 ? (
              <div className="bg-white rounded-xl shadow p-4 text-center">
                <p className="text-gray-600">No locations found. Try a different search term.</p>
              </div>
            ) : (
              filteredLocations.map((location) => (
                <div 
                  key={location.id}
                  onClick={() => toggleScheduled(location.id)}
                  className={`bg-white rounded-xl shadow mb-3 overflow-hidden cursor-pointer border-l-4 ${
                    location.scheduledForToday ? 'border-green-500' : 'border-transparent'
                  } transition-all hover:shadow-md`}
                >
                  <div className="p-3">
                    <div className="flex justify-between items-center">
                      <div>
                        <div className="flex items-center">
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-4 w-4 mr-1 text-sky-600" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" 
                            />
                          </svg>
                          <h3 className="font-bold text-gray-800">{location.name}</h3>
                        </div>
                        
                        <p className="text-xs text-gray-500 mt-1">
                          {location.address}
                        </p>
                        
                        <div className="text-xs text-gray-500 mt-1">
                          {location.hours}
                        </div>
                      </div>
                      
            
                      <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                        location.scheduledForToday 
                          ? 'bg-green-500 border-green-500 text-white' 
                          : 'border-gray-300'
                      }`}>
                        {location.scheduledForToday && (
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-4 w-4" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M5 13l4 4L19 7" 
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          
     
          <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200">
            <button
              onClick={saveSelections}
              disabled={loading || scheduledLocations.length === 0}
              className={`w-full py-4 rounded-xl font-bold text-lg flex items-center justify-center ${
                scheduledLocations.length > 0 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-500'
              }`}
            >
              {loading ? (
                <>
                  <svg 
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24"
                  >
                    <circle 
                      className="opacity-25" 
                      cx="12" 
                      cy="12" 
                      r="10" 
                      stroke="currentColor" 
                      strokeWidth="4"
                    ></circle>
                    <path 
                      className="opacity-75" 
                      fill="currentColor" 
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </>
              ) : (
                <>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-6 w-6 mr-2" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M17 8l4 4m0 0l-4 4m4-4H3" 
                    />
                  </svg>
                  Continue to Delivery ({scheduledLocations.length})
                </>
              )}
            </button>
          </div>
        </>
      )  */}
      {viewMode === 'select' ? (
  /* SELECT MODE - Pick locations and set quantities for today's deliveries */
  <>
    <div className="bg-white rounded-xl shadow p-4 mb-4">
      <h2 className="text-lg font-bold text-gray-800 mb-2">Select Locations for Today</h2>
      <p className="text-sm text-gray-600 mb-4">Tap on locations to add them to today's schedule and set quantities.</p>
      
      {/* Search box */}
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search locations..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 pl-10 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-sky-500"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 absolute left-3 top-3.5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      
      {/* Selected counter */}
      <div className="flex justify-between items-center mb-4 py-2 px-3 bg-sky-50 rounded-lg">
        <span className="text-sky-700 font-medium">Selected Locations:</span>
        <span className="bg-sky-100 text-sky-800 font-bold px-3 py-1 rounded-full">
          {scheduledLocations.length}
        </span>
      </div>
    </div>
    
    {/* Locations list for selection */}
    <div className="mb-24">
      {filteredLocations.length === 0 ? (
        <div className="bg-white rounded-xl shadow p-4 text-center">
          <p className="text-gray-600">No locations found. Try a different search term.</p>
        </div>
      ) : (
        filteredLocations.map((location) => (
          <div 
            key={location.id}
            className={`bg-white rounded-xl shadow mb-3 overflow-hidden border-l-4 ${
              location.scheduledForToday ? 'border-green-500' : 'border-transparent'
            } transition-all hover:shadow-md`}
          >
            <div className="p-3 border-b border-gray-100">
              <div 
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleScheduled(location.id)}
              >
                <div>
                  <div className="flex items-center">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-4 w-4 mr-1 text-sky-600" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" 
                      />
                    </svg>
                    <h3 className="font-bold text-gray-800">{location.name}</h3>
                  </div>
                  
                  <div className="flex items-center mt-1">
                    <a 
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location.address)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-xs text-sky-600"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-3 w-3 mr-1" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" 
                        />
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" 
                        />
                      </svg>
                      Directions
                    </a>
                  </div>
                  
                  <p className="text-xs text-gray-500 mt-1">
                    {location.address}
                  </p>
                  
                  <div className="text-xs text-gray-500 mt-1">
                    {location.hours}
                  </div>
                </div>
                
                {/* Selection Checkbox */}
                <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                  location.scheduledForToday 
                    ? 'bg-green-500 border-green-500 text-white' 
                    : 'border-gray-300'
                }`}>
                  {location.scheduledForToday && (
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-4 w-4" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M5 13l4 4L19 7" 
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            
            {/* Counters - Only show if selected */}
            {location.scheduledForToday && (
              <div className="p-3 flex justify-around bg-gray-50">
                {/* Pickup Counter */}
                <div className="flex flex-col items-center">
                  <div className="flex items-center mb-1 text-sm font-semibold text-green-600">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-4 w-4 mr-1" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M5 10l7-7m0 0l7 7m-7-7v18" 
                      />
                    </svg>
                    Pickup
                    {location.pickupReminder && (
                      <span className="ml-1 w-2 h-2 bg-blue-500 rounded-full inline-block"></span>
                    )}
                  </div>
                  
                  {/* Pickup Reminder */}
                  <label 
                    className="flex items-center mb-1 text-xs text-gray-600 cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="checkbox"
                      checked={location.pickupReminder}
                      onChange={() => togglePickupReminder(location.id)}
                      className="mr-1 h-3 w-3"
                    />
                    Reminder
                  </label>
                  <div className="text-xl font-bold text-green-600 mb-2">
                    {location.pickup}
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => updatePickup(location.id, -1)}
                      className="w-8 h-8 rounded-lg bg-red-100 text-red-600 font-bold border-none"
                    >
                      -
                    </button>
                    <button
                      onClick={() => updatePickup(location.id, 1)}
                      className="w-8 h-8 rounded-lg bg-green-100 text-green-600 font-bold border-none"
                    >
                      +
                    </button>
                  </div>
                </div>
                
                {/* Drop Counter */}
                <div className="flex flex-col items-center">
                  <div className="flex items-center mb-1 text-sm font-semibold text-red-600">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-4 w-4 mr-1" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M19 14l-7 7m0 0l-7-7m7 7V3" 
                      />
                    </svg>
                    Drop
                  </div>
                  <div className="h-5 mb-1"></div> {/* Spacer to align with pickup */}
                  <div className="text-xl font-bold text-red-600 mb-2">
                    {location.drop}
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => updateDrop(location.id, -1)}
                      className="w-8 h-8 rounded-lg bg-red-100 text-red-600 font-bold border-none"
                    >
                      -
                    </button>
                    <button
                      onClick={() => updateDrop(location.id, 1)}
                      className="w-8 h-8 rounded-lg bg-green-100 text-green-600 font-bold border-none"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </div>
    
    {/* Continue Button - Fixed at bottom */}
    <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200">
      <button
        onClick={saveSelections}
        disabled={loading || scheduledLocations.length === 0}
        className={`w-full py-4 rounded-xl font-bold text-lg flex items-center justify-center ${
          scheduledLocations.length > 0 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-500'
        }`}
      >
        {loading ? (
          <>
            <svg 
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24"
            >
              <circle 
                className="opacity-25" 
                cx="12" 
                cy="12" 
                r="10" 
                stroke="currentColor" 
                strokeWidth="4"
              ></circle>
              <path 
                className="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Saving...
          </>
        ) : (
          <>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-6 w-6 mr-2" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M17 8l4 4m0 0l-4 4m4-4H3" 
              />
            </svg>
            Continue to Delivery ({scheduledLocations.length})
          </>
        )}
      </button>
    </div>
  </>
)
      
      : (
        /* DELIVER MODE - Track deliveries */
        <>
          {/* Today's Schedule Card */}
          <div className="bg-white rounded-xl shadow p-4 mb-6">
            <div className="flex items-center justify-between mb-3">
              <h2 className="text-lg font-bold text-gray-800">Today's Schedule</h2>
              <button 
                onClick={() => setViewMode('select')}
                className="text-sm text-sky-600 font-medium px-3 py-1 bg-sky-50 rounded-lg flex items-center"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-4 w-4 mr-1" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" 
                  />
                </svg>
                Edit
              </button>
            </div>
            
            {/* Totals */}
            <div className="flex justify-between border-t pt-3 border-gray-200">
              <div className="text-center">
                <div className="text-xs text-gray-500">Locations</div>
                <div className="text-lg font-bold text-sky-600">
                  {scheduledLocations.length}
                </div>
              </div>
              <div className="text-center">
                <div className="text-xs text-gray-500">Pickup</div>
                <div className="text-lg font-bold text-green-600">
                  {totalPickup}
                </div>
              </div>
              <div className="text-center">
                <div className="text-xs text-gray-500">Drop</div>
                <div className="text-lg font-bold text-red-600">
                  {totalDrop}
                </div>
              </div>
              <div className="text-center">
                <div className="text-xs text-gray-500">Total</div>
                <div className="text-lg font-bold text-purple-600">
                  {totalItems}
                </div>
              </div>
            </div>
          </div>
          
          {/* Scheduled Locations */}
          <div className="mb-24">
            {scheduledLocations.length === 0 ? (
              <div className="bg-white rounded-xl shadow p-4 text-center">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-12 w-12 mx-auto mb-2 text-gray-400" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" 
                  />
                </svg>
                <p className="text-gray-600">No locations scheduled for today</p>
                <button 
                  onClick={() => setViewMode('select')}
                  className="mt-3 px-4 py-2 bg-sky-500 text-white rounded-lg font-medium"
                >
                  Select Locations
                </button>
              </div>
            ) : (
              scheduledLocations.map((location) => (
                <div 
                  key={location.id}
                  className={`bg-white rounded-xl shadow mb-3 overflow-hidden border-l-4 ${
                    location.completed ? 'border-green-500 bg-green-50' : 
                    location.deleted ? 'border-red-500 bg-red-50' : 
                    'border-sky-500'
                  }`}
                >
                  {/* Location Header */}
                  <div className="p-3 border-b border-gray-200">
                    <div className="flex justify-between items-center">
                      <div>
                        <div className="flex items-center">
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-4 w-4 mr-1 text-sky-600" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" 
                            />
                          </svg>
                          <h3 className="font-bold text-gray-800">{location.name}</h3>
                        </div>
                        
                        {/* Map Link */}
                        <a 
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location.address)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center mt-1 text-xs text-sky-600"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-3 w-3 mr-1" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" 
                            />
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" 
                            />
                          </svg>
                          Directions
                        </a>
                        
                        <div className="text-xs text-gray-500 mt-1">
                          {location.hours}
                        </div>
                      </div>
                      
                      {/* Status buttons */}
                      <div className="flex gap-2">
                        <button 
                          onClick={() => toggleDeleted(location.id)}
                          className="p-2 rounded-full bg-red-100 text-red-700"
                          aria-label="Remove location"
                          title="Remove location"
                        >
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-5 w-5" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M6 18L18 6M6 6l12 12" 
                            />
                          </svg>
                        </button>
                        
                        <button 
                          onClick={() => toggleCompleted(location.id)}
                          className={`p-2 rounded-full ${
                            location.completed ? 'bg-green-200 text-green-700' : 'bg-gray-100 text-gray-500'
                          }`}
                          aria-label="Complete location"
                          title="Complete location"
                        >
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-5 w-5" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M5 13l4 4L19 7" 
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  
                  {/* Counters */}
                  <div className="p-3 flex justify-around">
                    {/* Pickup Counter */}
                    <div className="flex flex-col items-center">
                      <div className="flex items-center mb-1 text-sm font-semibold text-green-600">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          className="h-4 w-4 mr-1" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M5 10l7-7m0 0l7 7m-7-7v18" 
                          />
                        </svg>
                        Pickup
                        {location.pickupReminder && (
                          <span className="ml-1 w-2 h-2 bg-blue-500 rounded-full inline-block"></span>
                        )}
                      </div>
                      
                      {/* Pickup Reminder */}
                      <label 
                        className="flex items-center mb-1 text-xs text-gray-600 cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <input
                          type="checkbox"
                          checked={location.pickupReminder}
                          onChange={() => togglePickupReminder(location.id)}
                          className="mr-1 h-3 w-3"
                          disabled={location.completed || location.deleted}
                        />
                        Reminder
                      </label>
                      <div className="text-xl font-bold text-green-600 mb-2">
                        {location.pickup}
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => updatePickup(location.id, -1)}
                          disabled={location.completed || location.deleted}
                          className="w-8 h-8 rounded-lg bg-red-100 text-red-600 font-bold border-none disabled:opacity-50"
                        >
                          -
                        </button>
                        <button
                          onClick={() => updatePickup(location.id, 1)}
                          disabled={location.completed || location.deleted}
                          className="w-8 h-8 rounded-lg bg-green-100 text-green-600 font-bold border-none disabled:opacity-50"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    
                    {/* Drop Counter */}
                    <div className="flex flex-col items-center">
                      <div className="flex items-center mb-1 text-sm font-semibold text-red-600">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          className="h-4 w-4 mr-1" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M19 14l-7 7m0 0l-7-7m7 7V3" 
                          />
                        </svg>
                        Drop
                      </div>
                      <div className="text-xl font-bold text-red-600 mb-2">
                        {location.drop}
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => updateDrop(location.id, -1)}
                          disabled={location.completed || location.deleted}
                          className="w-8 h-8 rounded-lg bg-red-100 text-red-600 font-bold border-none disabled:opacity-50"
                        >
                          -
                        </button>
                        <button
                          onClick={() => updateDrop(location.id, 1)}
                          disabled={location.completed || location.deleted}
                          className="w-8 h-8 rounded-lg bg-green-100 text-green-600 font-bold border-none disabled:opacity-50"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
        
{/* Schedule Notes - Add this after the scheduledLocations mapping */}
<div className="bg-white rounded-xl shadow p-4 mb-6">
  <div className="flex items-center mb-3">
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      className="h-5 w-5 text-yellow-500 mr-2" 
      viewBox="0 0 20 20" 
      fill="currentColor"
    >
      <path 
        fillRule="evenodd" 
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" 
        clipRule="evenodd" 
      />
    </svg>
    <h3 className="font-bold text-gray-800">Important Schedule Notes</h3>
  </div>
  
  <div className="pl-7 space-y-2 text-sm">
    <div className="flex items-start">
      <div className="w-2 h-2 bg-blue-500 rounded-full mt-1.5 mr-2 flex-shrink-0"></div>
      <p className="text-gray-700">
        <span className="font-medium">Smiley Lowell:</span> Pickup & drop on Monday, Wednesday, Friday
      </p>
    </div>
    
    <div className="flex items-start">
      <div className="w-2 h-2 bg-green-500 rounded-full mt-1.5 mr-2 flex-shrink-0"></div>
      <p className="text-gray-700">
        <span className="font-medium">Smiley Beverly:</span> Pickup & drop on Tuesday, Thursday
      </p>
    </div>
    
    <div className="pt-1 border-t border-gray-100">
      <p className="text-gray-500 font-medium mb-1">Closed Days:</p>
      <ul className="list-disc pl-5 text-gray-600 space-y-1">
        <li><span className="font-medium">Nobscot:</span> Closed Wednesday</li>
        <li><span className="font-medium">Tran:</span> Closed Thursday</li>
        <li><span className="font-medium">Bao Min See:</span> Closed Thursday</li>
        <li><span className="font-medium">Boston City:</span> Closed Tuesday</li>
      </ul>
    </div>
  </div>
  
  <div className="mt-3 pt-2 border-t border-gray-100 text-xs text-gray-500 flex items-center">
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      className="h-4 w-4 text-gray-400 mr-1" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke="currentColor"
    >
      <path 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth={2} 
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
      />
    </svg>
    Please check these schedules when planning your deliveries
  </div>
</div>
          </div>
          
          {/* Save Today Button - Fixed at bottom */}
          {scheduledLocations.length > 0 && (
            <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200">
              <button
                onClick={saveToday}
                disabled={loading || !allCompleted}
                className={`w-full py-4 rounded-xl font-bold text-lg flex items-center justify-center ${
                  allCompleted ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-500'
                }`}
              >
                {loading ? (
                  <>
                    <svg 
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" 
                      xmlns="http://www.w3.org/2000/svg" 
                      fill="none" 
                      viewBox="0 0 24 24"
                    >
                      <circle 
                        className="opacity-25" 
                        cx="12" 
                        cy="12" 
                        r="10" 
                        stroke="currentColor" 
                        strokeWidth="4"
                      ></circle>
                      <path 
                        className="opacity-75" 
                        fill="currentColor" 
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Saving...
                  </>
                ) : allCompleted ? (
                  <>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-6 w-6 mr-2" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M5 13l4 4L19 7" 
                      />
                    </svg>
                    Save Today's Deliveries
                  </>
                ) : (
                  'Complete All Locations to Save'
                )}
              </button>
            </div>
          )}
        </>
      )}
      {showSavePopup && (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-xl shadow-lg max-w-md w-full overflow-hidden">
      <div className="p-4 bg-blue-50 border-b border-blue-100 flex items-center">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-6 w-6 text-blue-500 mr-2" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <h3 className="font-bold text-blue-800 text-lg">Important Reminder</h3>
      </div>
      
      <div className="p-4">
        {isBeforeEarlyClosing() && (
          <div className="mb-4 bg-yellow-50 border border-yellow-100 rounded-lg p-3">
            <div className="flex items-start">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5 text-yellow-500 mt-0.5 mr-2 flex-shrink-0" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path 
                  fillRule="evenodd" 
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" 
                  clipRule="evenodd" 
                />
              </svg>
              <div>
                <p className="text-yellow-800 font-semibold">Early Closing Day!</p>
                <p className="text-yellow-700 text-sm">Today is {currentDay}, offices close at 2:00 PM. Please ensure all deliveries are completed before closing time.</p>
              </div>
            </div>
          </div>
        )}
        
        <p className="text-gray-700 mb-4">
          Always at the end of your route, call Unident Dental Care to see if there is any pickup.
        </p>
        
        <div className="bg-white border border-gray-200 rounded-lg p-3 mb-4">
          <div className="flex items-start">
            <div className="bg-blue-100 p-2 rounded-lg mr-3 flex-shrink-0">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5 text-blue-600" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" 
                />
              </svg>
            </div>
            <div>
              <h4 className="font-bold text-gray-800">Unident Dental Care</h4>
              <p className="text-gray-600 text-sm">200 Westgate Dr, Suite 119</p>
              <p className="text-gray-600 text-sm">Brockton, MA 02301</p>
              
              <a 
                href="https://www.google.com/maps/search/?api=1&query=Unident+Dental+Care+200+Westgate+Dr+Suite+119+Brockton+MA+02301"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center mt-2 text-blue-600 text-sm"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-4 w-4 mr-1" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" 
                  />
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" 
                  />
                </svg>
                Open in Maps
              </a>
            </div>
          </div>
        </div>
        
        <p className="text-sm text-gray-600 italic">
          Have you checked with Unident Dental Care for any pickups today?
        </p>
      </div>
      
      <div className="p-4 bg-gray-50 border-t border-gray-200 flex justify-end space-x-3">
        <button
          onClick={() => setShowSavePopup(false)}
          className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 font-medium"
        >
          Cancel
        </button>
        <button
          onClick={handleConfirmSave}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg font-medium"
        >
          Yes, Save Deliveries
        </button>
      </div>
    </div>
  </div>
)}
    </div>
    
  );
};

export default DeliverTodayScreen;