// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import HomeScreen from './components/HomeScreen'; // Adjust the import path if needed
// import DentalOfficeTracker from './components/DentalOfficeTracker';
// import DeliverTodayScreen from './components/DeliverTodayScreen';
// import HistoryScreen from './components/HistoryScreen';
// import LoginScreen from './components/Login';



// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path='/login' element={<LoginScreen />} />
//         <Route path="/" element={<HomeScreen />} />
//         <Route path="/deliver-today" element={<DeliverTodayScreen />} />
//         <Route path="/history" element={<HistoryScreen />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase'; // Adjust the import path if needed
import HomeScreen from './components/HomeScreen';
import DentalOfficeTracker from './components/DentalOfficeTracker';
import DeliverTodayScreen from './components/DeliverTodayScreen';
import HistoryScreen from './components/HistoryScreen';
import LoginScreen from './components/Login';

// ProtectedRoute component to handle auth check
const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, []);

  if (loading) {
    // Show loading state while checking auth
    return (
      <div className="min-h-screen flex items-center justify-center bg-blue-50">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  // If not authenticated, redirect to login with the return url
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If authenticated, render the requested route
  return children;
};

function App() {
  return (
    <Router>
      <Routes>
        {/* Public route - login page */}
        <Route path="/login" element={<LoginScreen />} />
        
        {/* Protected routes */}
        <Route path="/" element={
          <ProtectedRoute>
            <HomeScreen />
          </ProtectedRoute>
        } />
        
        <Route path="/deliver-today" element={
          <ProtectedRoute>
            <DeliverTodayScreen />
          </ProtectedRoute>
        } />
        
        <Route path="/history" element={
          <ProtectedRoute>
            <HistoryScreen />
          </ProtectedRoute>
        } />

        {/* Redirect any unknown routes to home */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;